export class SischefElectron {
    constructor() {
        console.log('window.pdvDesktop', window.pdvDesktop)

        window.pdvDesktop.onConnectionStatus((status) => {
            window.dispatchEvent(new CustomEvent('connection-status', {
                detail: { status }
            }))
        })

        window.pdvDesktop.onWhatsReady(() => {
            window.dispatchEvent(new CustomEvent('whats-ready'))
        })

        window.pdvDesktop.onRequestQR((qr) => {
            window.dispatchEvent(new CustomEvent('request-qr', {
                detail: { qr }
            }))
        })

        window.pdvDesktop.onNotification((content, opcional) => {
            console.log('notification', content, opcional)
            window.dispatchEvent(new CustomEvent('whats-notification', {
                detail: { notification: { content, opcional } }
            }))
        })

    }

    createWhatsClient() {
        return new Promise((resolve, reject) => {
            window.pdvDesktop.createWhatsClient().then(resolve).catch(reject)
        })
    }

    logoutWhats() {
        return new Promise((resolve, reject) => {
            window.pdvDesktop.logoutWhats().then(resolve).catch(reject)
        })
    }

    sendMessage(number, message) {
        return new Promise((resolve, reject) => {
            window.pdvDesktop.sendMessage(number, message).then(resolve).catch(reject)
        })
    }

    async checkNumber(number) {
        return new Promise((resolve, reject) => {
            console.log('number', number)
            window.pdvDesktop.checkNumber(number).then(resolve).catch(reject)
        })
    }

    checkStatus() {
        return new Promise((resolve, reject) => {
            window.pdvDesktop.checkStatus().then(resolve).catch(reject)
        })
    }

    doNotification(message) {
        return new Promise((resolve, reject) => {
            window.pdvDesktop.doNotification(message).then(resolve).catch(reject)
        })
    }


}