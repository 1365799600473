import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText, FormControlLabel, Checkbox, Grid, Box, Link } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import AuthService from '../util/AuthService';
import Alert from '@mui/material/Alert';

function LoginForm() {
  const service = new AuthService()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const [hash, setHash] = useState('')
  const [enviando, setEnviando] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)
  const [termsChecked, setTermsChecked] = useState(false)

  useEffect(() => {
    if (service.loggedIn()) navigate('/home')

    const terms = localStorage.getItem('_terms')
    setTermsChecked(terms === 'true')
  }, [])

  function onClickSubmit(e) {
    e.preventDefault()

    if (!termsChecked) {
      enqueueSnackbar('Você precisa concordar com os termos de uso.', { variant: 'error' })
      return
    }

    setEnviando(true)
    service.login(hash)
    enqueueSnackbar('Login realizado com sucesso!', { variant: 'success' })
    navigate('/home')
    setEnviando(false)
  }

  function onTextChange(e) {
    setHash(e.target.value)
  }

  const handleTermsClick = () => {
    setOpenDialog(true)
  }

  const handleDialogClose = () => {
    setOpenDialog(false)
  }

  function handleSubmitTerms(term) {
    setOpenDialog(false)
    setTermsChecked(term)
    localStorage.setItem('_terms', term)
  }

  return (
    <>
      <Grid container justifyContent="center" alignItems="center" sx={{ pt: 10 }}>
        <Box textAlign="center">
          <WhatsAppIcon color="primary" style={{ fontSize: 60, marginBottom: 10 }} />
          <Typography variant="h4" component="h1">
            Sischef WhatsApp Connect
          </Typography>
        </Box>
      </Grid>

      <Container maxWidth="sm">
        <Typography variant="subtitle1" sx={{ mt: 5 }}>
          Insira a chave de acesso para ser direcionado à tela de conexão.
        </Typography>
        <form onSubmit={onClickSubmit} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <CardContent sx={{ width: '100%' }}>
            <TextField
              variant="outlined"
              required
              id="hash"
              value={hash}
              fullWidth
              label="Chave de acesso"
              onChange={onTextChange}
            />
          </CardContent>
          <CardActions sx={{ justifyContent: 'center', width: '100%', mb: 2 }}>
            <Button type="submit" size="large" variant="contained" disabled={enviando}>
              {enviando ? 'Acessando...' : 'Acessar'}
            </Button>
          </CardActions>
        </form>

        <Grid textAlign="center" sx={{ marginTop: 2 }}>
          <HelpDialog />
        </Grid>

        <Typography variant="body2" sx={{ textAlign: 'center', mt: 2 }}>
          Ao acessar, você concorda com os
          <Box component="span" sx={{ ml: 0.5, color: (theme) => theme.palette.primary.main, cursor: 'pointer', textDecoration: 'underline' }} onClick={handleTermsClick}>
            Termos de uso e responsabilidades.
          </Box>
        </Typography>

        <TermsDialog
          open={openDialog}
          onClose={handleDialogClose}
          onSubmitTerms={handleSubmitTerms} />

      </Container>
    </>
  )
}

function HelpDialog() {
  const [open, setOpen] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div>
      <Button onClick={handleClickOpen} sx={{ color: 'primary' }}>
        Como obter a chave de acesso?
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}>
        <DialogTitle>{"Obter a chave de acesso"}</DialogTitle>
        <DialogContent>
          <DialogContentText color='black'>
            Acesse o Sischef:
            <ul>
              <li>No Header principal, clique em 'Dados da empresa'</li>
              <li>Aba 'Integrações'</li>
              <li>Token da empresa.</li>
            </ul>
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} autoFocus sx={{ color: 'primary' }}>
            Entendi
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

function TermsDialog({ open, onClose, onSubmitTerms }) {
  const [termsChecked, setTermsChecked] = useState(false)

  useEffect(() => {
    const terms = localStorage.getItem('_terms')
    setTermsChecked(terms === 'true')
  }, [open])

  const handleCheckboxChange = (e) => {
    setTermsChecked(e.target.checked)
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}>
      <DialogTitle>
        Termos de Uso e Responsabilidades
      </DialogTitle>
      <DialogContent>
        <Alert severity="error">
          <Typography>
            Há uma possibilidade de que o WhatsApp bloqueie seu número. Por isso, é importante ler atentamente as informações a seguir.
          </Typography>
        </Alert>
        <DialogContentText color='black'>
          <br />
          Ao usar este serviço, você está ciente de que qualquer bloqueio do número de WhatsApp não é de nossa responsabilidade. Para mais informações sobre boas práticas, clique no link abaixo:
          <br />
          <br />
          <Link href="https://sischef.space/article/boas-praticas-whatsapp-connect/" target="_blank" rel="noopener noreferrer">
            Boas práticas para utilizar o WhatsApp Connect
          </Link>
          <br />
          <br />
        </DialogContentText>
        <FormControlLabel
          control={<Checkbox checked={termsChecked} onChange={handleCheckboxChange} />}
          label="Eu li e concordo com os Termos de uso e responsabilidades."
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancelar
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => onSubmitTerms(termsChecked)}>
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default LoginForm