import React, { useState, useEffect } from 'react'
import { makeStyles } from '@mui/styles'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import Button from '@mui/material/Button'
import NotificationsIcon from '@mui/icons-material/Notifications'
import MenuIcon from '@mui/icons-material/Menu'
import AuthService from '../../util/AuthService'
import { Badge, DialogTitle, Drawer, ListItem, ListItemText, List } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import Alert from '@mui/material/Alert';
import { useSischefWhatsapp } from '../../interfaces/SischefWhatsapp'
import { useLocation, useNavigate } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: '5px',
  },
  title: {
    flexGrow: 1,
  },
  drawer: {
    width: 310,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  closeButton: {
    display: 'flex',
    justifyContent: 'flex-start',
    margin: 4,
    color: 'black'
  },
  notificationList: {
    flexGrow: 1, // Para que a lista de notificações ocupe todo o espaço restante
    overflowY: 'auto', // Caso haja muitas notificações, permite rolar a lista
  },
}))

export default function HeaderAppBar() {
  const classes = useStyles()
  const navigate = useNavigate()
  const location = useLocation()
  const service = new AuthService()
  const sischefWhatsapp = useSischefWhatsapp()
  const [anchorEl, setAnchorEl] = useState(null)
  const [notifications, setNotifications] = useState([])
  const [openLogoutConfirm, setOpenLogoutConfirm] = useState(false)
  const [openDisconnectConfirm, setOpenDisconnectConfirm] = useState(false)
  const [notificationsDrawerOpen, setNotificationsDrawerOpen] = useState(false)
  const [notificationsViewed, setNotificationsViewed] = useState(null)

  console.log(location.pathname)

  const handleLogoutClick = () => {
    setOpenLogoutConfirm(true) // Abrir o dialog de confirmação
  }

  const handleDisconnectClick = () => {
    setOpenDisconnectConfirm(true) // Abrir o dialog de confirmação
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const confirmLogout = () => {
    service.logout()
    setOpenLogoutConfirm(false); // Fechar o diálogo de confirmação após logout
    handleClose()
    sischefWhatsapp.logoutWhats()
  }

  const cancelLogout = () => {
    setOpenLogoutConfirm(false) // Fechar o diálogo de confirmação sem fazer logout
  }

  const confirmDisconnect = () => {
    setOpenDisconnectConfirm(false); // Fechar o diálogo de confirmação após desconectar
    handleClose()
    sischefWhatsapp.logoutWhats().then(() => navigate('/home'))
  }

  const cancelDisconnect = () => {
    setOpenDisconnectConfirm(false) // Fechar o diálogo de confirmação sem fazer desconectar
  }

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleNotificationClick = (event) => {
    setNotificationsDrawerOpen(true)
    setNotificationsViewed(true)
  }

  const handleDrawerClose = () => {
    setNotificationsDrawerOpen(false)
  }

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date().getTime()
      setNotifications(prevNotifications =>
        prevNotifications.filter(notification => (now - notification.timestamp) < 24 * 60 * 60 * 1000)
      )
    }, 60000) // verifica a cada minuto
    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    function handleNotification(event) {
      const notification = event.detail.notification
      console.log('Notificação Erro:', notification)
      setNotifications(prevNotifications => [...prevNotifications, { notification, timestamp: new Date().getTime() }])
      setNotificationsViewed(false) // Resetar o estado de visualização quando uma nova notificação chegar
    }

    window.addEventListener('whats-notification', handleNotification)

    return () => {
      window.removeEventListener('whats-notification', handleNotification)
    }
  }, [])

  return (
    <div className={classes.root}>

      {/* Diálogo de Confirmação de Desconectar */}
      <Dialog open={openDisconnectConfirm} onClose={cancelDisconnect}>
        <DialogTitle>Deseja realmente se desconectar?</DialogTitle>

        <DialogContent>
          <DialogContentText>Tem certeza de que deseja se desconectar?</DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={cancelDisconnect} color="secondary">Cancelar</Button>
          <Button onClick={confirmDisconnect} color="primary">Confirmar</Button>
        </DialogActions>
      </Dialog>

      {/* Diálogo de Confirmação de Logout */}
      <Dialog open={openLogoutConfirm} onClose={cancelLogout}>
        <DialogTitle>Deseja realmente sair?</DialogTitle>

        <DialogContent>
          <DialogContentText>Tem certeza de que deseja sair?</DialogContentText>
          <DialogContentText>Ao confirmar, as notificações serão perdidas.</DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={cancelLogout} color="secondary">Cancelar</Button>
          <Button onClick={confirmLogout} color="primary">Confirmar</Button>
        </DialogActions>
      </Dialog>

      <AppBar position="static">
        <Toolbar>

          <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={handleMenuClick}>
            <MenuIcon />
          </IconButton>

          <Typography variant="h6" className={classes.title}>
            WhatsApp Sischef Connect
          </Typography>

          <Typography>
            <IconButton
              color="inherit"
              title="Notificações"
              aria-label="notifications"
              onClick={handleNotificationClick}>
              {!notificationsViewed && (
                <Badge overlap="circular" badgeContent={notifications.length} variant="dot"
                  sx={{
                    '& .MuiBadge-dot': {
                      backgroundColor: 'white'
                    }
                  }}>
                  <NotificationsIcon />
                </Badge>
              )}
              {notificationsViewed && (
                <NotificationsIcon />
              )}
            </IconButton>
          </Typography>
        </Toolbar>
      </AppBar>

      <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>

        {location.pathname === '/status' ? (
          <MenuItem onClick={handleDisconnectClick}>
            <PowerSettingsNewIcon fontSize="small" color="primary" style={{ marginRight: '5px' }} />
            <Typography color="primary">Desconectar</Typography>
          </MenuItem>
        ) : null}

        <MenuItem onClick={handleLogoutClick}>
          <ExitToAppIcon fontSize="small" color="primary" style={{ marginRight: '5px' }} />
          <Typography color="primary">Sair</Typography>
        </MenuItem>
      </Menu>

      <Drawer anchor="right" open={notificationsDrawerOpen} onClose={handleDrawerClose}>
        <div className={classes.drawer}>
          <div className={classes.header}>
            <IconButton onClick={handleDrawerClose} className={classes.closeButton}>
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" component="div" style={{ fontWeight: 'bold', marginLeft: '40px' }}>
              Notificações
            </Typography>
          </div>

          <List className={classes.notificationList}>
            {notifications.length === 0 ? (
              <ListItem>
                <ListItemText primary="Nenhuma nova notificação" sx={{ textAlign: 'center' }} />
              </ListItem>
            ) : (
              notifications.map((item, index) => (
                <div key={index}>
                  <ListItem sx={{ pb: 0, pt: '2px' }}>
                    <ListItemText>
                      <Alert severity="error">
                        {item.notification?.content?.split('\n').map((line, i) => (
                          <Typography key={i}>{line}</Typography>
                        ))}
                      </Alert>
                    </ListItemText>
                  </ListItem>
                  {index < notifications.length - 1}
                </div>
              ))
            )}
          </List>
        </div>
      </Drawer>

    </div>
  )
}