export default class AuthService {

    login(hash) {
        localStorage.setItem('_accessKey', hash)
    }

    loggedIn() {
        return localStorage.getItem('_accessKey') ? true : false
    }

    logout() {
        localStorage.removeItem('_accessKey')
        window.location.replace('/')
    }

    getAccessKey() {
        return localStorage.getItem('_accessKey')
    }

    setAccessKey(accessKey) {
        localStorage.setItem("_accessKey", JSON.stringify(accessKey))
    }

    getRoom() {
        const accessKey = localStorage.getItem('_accessKey')

        return accessKey + process.env.REACT_APP_SOCKET_ROOM
    }

    getQuery() {
        return 'room=' + this.getRoom() + '&auth=' + process.env.REACT_APP_WEBSOCKET_AUTH_TOKEN
    }

}