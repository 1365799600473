import React, { useEffect, useState } from 'react';
import StatusIcon from '../../images/StatusIcon.svg';
import { useNavigate } from 'react-router-dom';
import HeaderAppBar from '../header';
import Button from '@mui/material/Button';
import { io } from 'socket.io-client';
import { formatFullAddressDelivery } from '../../customer/helper';
import AuthService from '../../util/AuthService';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useSischefWhatsapp } from '../../interfaces/SischefWhatsapp';

function ConnectionStatus() {
    const service = new AuthService()
    const navigate = useNavigate()
    const [status, setStatus] = useState('')
    const [socket, setSocket] = useState(null)
    const [isLoading, setIsLoading] = useState(true)
    const [isConnected, setIsConnected] = useState(false)
    const sischefWhatsapp = useSischefWhatsapp()

    function updateStatusSocket(status) {
        if (!socket) return

        socket.emit('sendMessageSocket', {
            room: service.getRoom(),
            type: 'WHATSAPP_STATUS_CHANGE',
            status: status ? 'connected' : 'disconnected',
        })
    }

    function normalizeNumber(numberStr) {
        let normalized = numberStr.replace(/\D/g, '')

        if (normalized.indexOf('55') < 0) {
            normalized = '55' + normalized
        }

        if (normalized.startsWith('0800')) {
            return null //número inválido
        }

        //extrai os primeiros 11 dígitos se houver mais
        normalized = normalized.substring(0, 13)

        if (normalized.length !== 13) {
            return null
        }

        return normalized
    }

    useEffect(() => {
        function initSocket() {
            if (socket) return

            const webSocket = io(process.env.REACT_APP_SOCKET_URL, {
                query: service.getQuery(),
                transports: ['websocket'],
            });

            console.log('Socket instanciado', webSocket)

            webSocket.on("connect", () => {
                sischefWhatsapp.checkStatus().then(status => {
                    console.log('status', status)
                    setIsLoading(false)
                    setIsConnected(status)
                })

                console.log('connect SocketIO', webSocket.id)
            });

            webSocket.on("disconnect", (msg) => {
                console.log('disconnect SocketIO', webSocket.id)
                console.log("Disconnection message", msg);
            });

            setSocket(webSocket)
        }

        function handleConnectionStatus(event) {
            console.log('status', event.detail.status)
            setIsLoading(false)
            setIsConnected(event.detail.status === 'connected')
        }

        window.addEventListener('connection-status', handleConnectionStatus)

        initSocket()

        return () => {
            if (socket) {
                socket.disconnect()
            }

            window.removeEventListener('connection-status', handleConnectionStatus)
        }
    }, [])

    useEffect(() => {
        updateStatusSocket(isConnected)

        if (isConnected) {
            setStatus('Conexão estabelecida com sucesso!')
        } else {
            setStatus('Desconectado. Por favor, escaneie o QR code novamente.')
        }
    }, [isConnected, socket])

    useEffect(() => {
        if (!socket) return

        socket.room = service.getRoom()

        // Recebe um update de socket de outros usuários socket
        socket.on('Update', (event) => onMessageReceive(event))

        // Recebe um update do socket do servidor
        socket.on('reciveSocketMessage', (event) => onMessageReceive(event))
    }, [socket])

    const handleOrderUpdate = (newOrder, message) => {
        const { phone, name } = newOrder?.iFoodOrder?.customer

        // se não tiver telefone, ignora e não envia nada
        if (!phone) {
            return
        }

        const normalizedPhone = normalizeNumber(phone)
        if (!normalizedPhone) {
            console.log('Número não foi informado ou não é válido')
            sischefWhatsapp.doNotification(`Número não foi informado ou não é válido.\nPedido: ${newOrder.id}\nCliente: ${name}`)
            return
        }

        // envia a mensagem para o número de telefone do cliente
        console.log('Número normalizado', normalizedPhone)

        sischefWhatsapp.checkNumber(normalizedPhone).then((validation) => {
            console.log(validation)
            if (!validation.canReceiveMessage || !validation.numberExists) {
                console.log("Erro: Número informado não pode receber mensagens ou o número é inválido.");
                sischefWhatsapp.doNotification(
                    `Número ${normalizedPhone} não pode receber mensagens ou o número é inválido.\nPedido: ${newOrder.id}\nCliente: ${name}`
                )
                return
            }

            console.log('Mensagem válida', validation.id.user)

            sischefWhatsapp.sendMessage(validation.id.user, message).then(() => {
                console.log(`Menssagem enviada para o cliente com número: ${normalizedPhone}`)
            })
        })

    }

    const onMessageReceive = (event) => {
        let data = event
        const { type, newOrder } = data

        console.log("socket event recebido - type -> ", type)
        console.log('neworder', newOrder)

        if (type === 'WHATSAPP_STATUS_REFRESH') {
            sischefWhatsapp.checkStatus().then(status => {
                console.log('status', status)
                updateStatusSocket(status)
            })
            return
        }

        console.log('==========================')
        console.log('type, newOrder?.situacao')
        console.log(type, newOrder?.situacao)

        if (type === 'ADD_OR_UPDATE_GENERIC_FROM_ORDER' && newOrder && newOrder.situacao === 'CONFIRMADO') {
            if (newOrder.iFoodOrder.deliveryMethod.mode === 'DELIVERY') {
                handleOrderUpdate(newOrder, getOrderConfirmationMessage(newOrder))
            } else if (newOrder.iFoodOrder.deliveryMethod.mode === 'TAKEOUT') {
                handleOrderUpdate(newOrder, getOrderConfirmationMessage(newOrder))
            }
        }

        if (type === 'UPDATE_STATUS_ORDER' && newOrder && newOrder.situacao === 'ENVIADO') {
            if (newOrder.iFoodOrder.deliveryMethod.mode === 'DELIVERY') {
                const message = 'Oba! Seu pedido está a caminho da entrega! 🛵 Mal posso esperar para você recebê-lo!'
                handleOrderUpdate(newOrder, message)
            }
        }

        if (type === 'UPDATE_STATUS_ORDER' && newOrder && newOrder.situacao === 'AGUARDANDO_RETIRADA') {
            if (newOrder.iFoodOrder.deliveryMethod.mode === 'TAKEOUT') {
                const message = 'Oba! Seu pedido está prontinho e aguardando ansiosamente por você para buscá-lo! 📦'
                handleOrderUpdate(newOrder, message)
            }
        }

        if (type === 'UPDATE_STATUS_ORDER' && newOrder && newOrder.situacao === 'ENTREGUE') {
            if (newOrder.iFoodOrder.deliveryMethod.mode === 'DELIVERY') {
                const message = 'Seu pedido foi entregue! 🎉 Agradecemos sua confiança!'
                handleOrderUpdate(newOrder, message)
            } else if (newOrder.iFoodOrder.deliveryMethod.mode === 'TAKEOUT') {
                const message = 'Seu pedido foi retirado! 🎉 Agradecemos sua confiança!'
                handleOrderUpdate(newOrder, message)
            }
        }

        if (type === 'UPDATE_STATUS_ORDER' && newOrder && newOrder.situacao === 'CANCELADO') {
            const message = 'Ah, que pena! 😥 Seu pedido infelizmente foi cancelado pelo restaurante.'
            handleOrderUpdate(newOrder, message)
        }

    }

    const getOrderConfirmationMessage = (order) => {
        const {
            id,
            iFoodOrder: { items, deliveryAddress, totalPrice, deliveryFee, payments }
        } = order;

        const arr = []
        const formatAddress = formatFullAddressDelivery(deliveryAddress)
        const isDelivery = order.iFoodOrder.deliveryMethod.mode === 'DELIVERY';
        const isTakeout = order.iFoodOrder.deliveryMethod.mode === 'TAKEOUT';

        if (isDelivery || isTakeout) {
            arr.push(`Olá, ${order?.iFoodOrder?.customer?.name}! Seu pedido foi realizado e *confirmado* com sucesso! Aqui está o resumo do que você escolheu:`)
            arr.push(`\nNúmero do Pedido: *#${id}*`)
        }

        if (isDelivery) {
            const address = `${formatAddress.address} ${formatAddress.complement ? `- ${formatAddress.complement}` : ''}`
            arr.push(`Endereço de entrega 🛵: *${address.trim()}*`)
        } else if (isTakeout) {
            arr.push('*Retirada no local* 🏬');
        }

        const itens = items.map(item => {
            const msg = []

            msg.push(`➡️ _${item.quantity}x ${item.name} - R$ ${item.price.toFixed(2)}_`)

            if (Array.isArray(item.subItems)) {
                item.subItems.map(subItem => msg.push(`     _- ${subItem.quantity}x ${subItem.name} - R$ ${subItem.price.toFixed(2)}_`))
            }

            return msg.join('\n')
        }).join('\n')


        arr.push('\n*Itens*:');
        arr.push(`${itens}`);

        // Verificar métodos de pagamento
        const levarMaquinaCartao = order.iFoodOrder.levarMaquinaCartao
        const levarTrocoPara = order.iFoodOrder.levarTrocoPara

        arr.push('\nForma de Pagamento:');
        if (levarMaquinaCartao) {
            arr.push('Levar máquininha de cartão 💳');
        } else if (levarTrocoPara) {
            arr.push(`Levar troco para: *R$ ${levarTrocoPara.toFixed(2)}* 💵`);
        } else {
            const pagamento = payments.length > 0 ? payments[0].name : 'Nenhuma forma de pagamento informada';
            arr.push(`*${pagamento.trim()}*`);
        }

        if (isDelivery) {
            const taxaEntrega = deliveryFee > 0 ? `R$ ${deliveryFee.toFixed(2)}` : 'Grátis'
            arr.push(`\nTaxa de Entrega: *${taxaEntrega}*`);
        }

        const total = `R$ ${totalPrice.toFixed(2)}`
        arr.push(`\nTotal: *${total}*`)

        if (order.iFoodOrder.observations) {
            arr.push('\nObservações:')
            arr.push(`*${order.iFoodOrder.observations}*`)
        }

        return arr.join('\n')
    }

    const handleButtonClick = () => {
        navigate('/home');
    }

    if (isLoading) {
        return (
            <>
                <Box>
                    <HeaderAppBar />
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: '160px', marginRight: '40px' }}>
                    <CircularProgress />
                    <Typography variant="h5">Sincronizando com o Whatsapp...</Typography>
                </Box>
            </>
        )
    }

    return (
        <>
            <Box>
                <HeaderAppBar />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: '160px', marginRight: '40px' }}>
                <img src={StatusIcon} alt="Status Icon" style={{ width: '200px', height: 'auto' }} />
                <Typography
                    mt={2}
                    variant="h5">
                    {status}
                </Typography>
                {isConnected && (
                    <Typography variant="subtitle1">
                        A partir de agora, ao alterar o status de algum pedido o mesmo será informado para o cliente
                    </Typography>
                )}
                {!isConnected && (
                    <Button
                        sx={{ mt: 2 }}
                        size='small'
                        color='primary'
                        variant="outlined"
                        onClick={handleButtonClick}>
                        Escanear QR code
                    </Button>
                )}
            </Box>
        </>
    )
}

export default ConnectionStatus