import React, { useEffect, useState, useRef } from 'react'
import QRCode from 'qrcode.react'
import { useNavigate } from 'react-router-dom'

import { Container, Box, Typography, List, ListItem, ListItemText, ListItemIcon, Grid, CircularProgress } from '@mui/material'
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid'
import SettingsIcon from '@mui/icons-material/Settings'
import LinkIcon from '@mui/icons-material/Link'
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner'
import HeaderAppBar from '../header'
import { useSischefWhatsapp } from '../../interfaces/SischefWhatsapp'

function HomePrintMonitor() {
    const navigate = useNavigate()
    const [qrUrl, setQrUrl] = useState('')
    const [qrGenerating, setQrGenerating] = useState(true)
    const isClientCreated = useRef(false)
    const sischefWhatsapp = useSischefWhatsapp()

    useEffect(() => {

        function onRequestQRHandler(event) {
            console.log('QR Code recebido:', event.detail.qr)

            if (!event.detail.qr) {
                setQrUrl('')
                setQrGenerating(true)
                return
            }

            setQrUrl(event.detail.qr)
            setQrGenerating(false)
        }

        console.log('Chamando sischefWhatsapp.createWhatsClient()')
        sischefWhatsapp.createWhatsClient()
            .then(() => {
                console.log('createWhatsClient executado com sucesso')
                isClientCreated.current = true
            })
            .catch(error => console.error('Erro ao executar createWhatsClient:', error))

        function handleConnectionStatus() {
            console.log('WhatsApp está pronto')
            navigate('/status')
        }

        window.addEventListener('whats-ready', handleConnectionStatus)
        window.addEventListener('request-qr', onRequestQRHandler)

        return () => {
            window.removeEventListener('whats-ready', handleConnectionStatus)
            window.removeEventListener('request-qr', onRequestQRHandler)
        }
    }, [])

    return (
        <React.Fragment>
            <HeaderAppBar />
            <Container maxWidth="md">
                <Box pt={5} pb={2}>
                    <Typography variant="h4" component="h1" gutterBottom>
                        Conecte seu WhatsApp
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom>
                        Use o WhatsApp para atualizar seus clientes
                    </Typography>
                </Box>
                <Grid container spacing={6} pt={2}>
                    <Grid item xs={12} md={8} >
                        <Box pl={1}>
                            <List>
                                <ListItem>
                                    <ListItemIcon>
                                        <PhoneAndroidIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={<Typography variant="body2">Abra o <strong>WhatsApp</strong> no seu celular.</Typography>} />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <SettingsIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={<Typography variant="body2">Toque em <strong>Mais opções</strong> no Android ou em <strong>Configurações</strong> no iPhone.</Typography>} />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <LinkIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={<Typography variant="body2">Toque em <strong>Dispositivos conectados</strong> e, em seguida, em <strong>Conectar dispositivo</strong>.</Typography>} />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <QrCodeScannerIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={<Typography variant="body2">Aponte seu celular para esta tela para escanear o QR code.</Typography>} />
                                </ListItem>
                            </List>
                        </Box>
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <Box display="flex" justifyContent="center" alignItems="center">
                            {qrGenerating ? (
                                <React.Fragment>
                                    <Box>
                                        <CircularProgress size={30} />
                                    </Box>
                                    <Typography variant="body2" sx={{ ml: 1 }}>O QR code está sendo gerado. <strong>Aguarde...</strong></Typography>
                                </React.Fragment>
                            ) : (
                                qrUrl ? (
                                    <QRCode value={qrUrl} size={208} />
                                ) : (
                                    <Typography variant="body2">URL do QR code não definido. Favor entrar em contato com o suporte</Typography>
                                )
                            )}
                        </Box>

                    </Grid>
                </Grid>
            </Container>
        </React.Fragment>
    )

}

export default HomePrintMonitor;
