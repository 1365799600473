
export const formatFullAddressDelivery = (address) => {

    let fullAddress = { address: '', complement: '' }

    if (address) {
        let addressFormated = ''

        if (address.streetName) {
            addressFormated += address.streetName
        }

        if (address.streetNumber) {
            addressFormated += ', ' + address.streetNumber
        }

        if (address.neighborhood) {
            addressFormated += ' - ' + address.neighborhood
        }
        //FAZ UMMA COPIA SOMENTE COM O ENDERECO
        fullAddress.onlyAddress = addressFormated.toString();

        if (address.city) {
            addressFormated += ', ' + address.city
        }

        if (address.state) {
            addressFormated += ' - ' + address.state
        }

        let complementFormated = ''

        if (address.reference) {
            complementFormated += address.reference
        }

        fullAddress.address = addressFormated
        fullAddress.complement = complementFormated
    }

    return fullAddress
}