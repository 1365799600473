const { SischefElectron } = require("./SischefElectron")

class SischefWhatsapp {
    constructor(config = 'ELECTRON') {
        this.config = config

        if (this.config === 'ELECTRON') {
            this.app = new SischefElectron()
        }
        // else if (this.api === 'ZAPI') {
        //     this.sischef = new SischefZAPI()
        // } 
        else {
            throw new Error('API inválida')
        }
    }

    createWhatsClient() {
        return this.app.createWhatsClient()
    }

    logoutWhats() {
        return this.app.logoutWhats()
    }

    sendMessage(number, message) {
        return this.app.sendMessage(number, message)
    }

    checkNumber(number) {
        return this.app.checkNumber(number)
    }

    checkStatus() {
        return this.app.checkStatus()
    }

    doNotification() {
        return this.app.doNotification()
    }

}

export function useSischefWhatsapp() {
    if (window.SischefWhatsapp) return window.SischefWhatsapp

    window.SischefWhatsapp = new SischefWhatsapp()

    return window.SischefWhatsapp
}