import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import LoginForm from './components/login-form'
import HomePrintMonitor from './components/homeWhats'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import ConnectionStatus from './components/homeWhats/screenStatus'
import { SnackbarProvider } from 'notistack';

const theme = createTheme({
  dividerFullWidth: {
    margin: `5px 0 0 2px`,
  },
  dividerInset: {
    margin: `5px 0 0 2px`,
  },
  palette: {
    primary: {
      main: '#f10b5c',
    },
    secondary: {
      main: '#757575',
    },
  },
})

function App() {

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <SnackbarProvider
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          autoHideDuration={5000}
        >
          <Router>
            <Routes>
              <Route path="/" element={<LoginForm />} />
              <Route path="/home" element={<HomePrintMonitor />} />
              <Route path="/status" element={<ConnectionStatus />} />
            </Routes>
          </Router>
        </SnackbarProvider>
      </ThemeProvider>
    </div>
  )
}

export default App